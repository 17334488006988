// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableStaticAttributes(staticattribute) {
	const controller = '/pmstaticattributes/disableStaticAttributes';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		staticattribute,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableStaticAttributes(staticattribute) {
	const controller = '/pmstaticattributes/enableStaticAttributes';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		staticattribute,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

/* New actions - Visibility enable/disable
export async function disableVisibilities(spatialtable) {
	const controller = '/pmstaticattributes/disableVisibilities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		spatialtable,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableVisibilities(spatialtable) {
	const controller = '/pmstaticattributes/enableVisibilities';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		spatialtable,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
*/
